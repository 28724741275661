import Parser from 'ninelines-ua-parser';

const parser = new Parser();
let mobile = false;

if (parser.getDevice().type === 'mobile' || parser.getDevice().type === 'tablet' || innerWidth <= 768) {
	mobile = true;
}

export {mobile};

export default {
	$document: $(document),
	$window: $(window),
	$body: $('body'),
	$bodyHtml: $('body, html'),
	$header: $('.header'),

	$orderForm: $('form.order'),
	$orderFormBtn: $('.order__submit'),

	$navLink: $('.nav__link'),
	$mobileLink: $('.mobile-menu__link'),
	$link: $('.js-link'),
	$hamburger: $('.js-hamburger'),
	$mobileMenu: $('.js-mobile-menu'),
};
