import vars from './vars';

function clearText(text) {
	return text.toString().trim().replace(/\s+/g, ' ');
}

vars.$orderFormBtn.on('click', () => {
	gtag('event', 'Click', {
		'event_category': 'Form Button',
		'event_label': 'Order form - clicked'
	});
});

vars.$orderForm.submit(() => {
	gtag('event', 'Submit', {
		'event_category': 'Form Submit',
		'event_label': 'Order form - submitted'
	});
});
