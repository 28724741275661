import vars, {mobile} from './vars';

// Main Menu Links with Hash
if (vars.$navLink.length) {
	vars.$navLink.add(vars.$mobileLink).add(vars.$link).on('click', (event) => {
		let link = event.currentTarget;

		// toggle active state for current link
		vars.$navLink.add(vars.$mobileLink).add(vars.$link).removeClass('is-active');
		$(link).addClass('is-active');

		if (/#/.test(link.href) && location.pathname === link.pathname) {
			event.preventDefault();

			if (mobile) {
				vars.$hamburger.removeClass('is-active');
				vars.$mobileMenu.removeClass('is-open');
			}

			let url = $(link).attr('href');
			let hash = url.split('#')[1];
			let id = `#${hash}`;
			let top = $(id).offset().top;

			vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
		}
	});
}

// Page load - anchor navigation
if (location.hash) {
	vars.$window.on('load', () => {
		let id = location.hash;
		let top = $(id).offset().top;

		vars.$bodyHtml.animate({scrollTop: top}, 500, 'swing');
	});
}
